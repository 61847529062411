import {useMutation, useQuery} from "react-query";
import {DOMAIN_OVERALL_DASHBOARD, EASM_INSIGHTS_DETAILS, EASM_OVERALL_DASHBOARD, DOMAIN_SPECIFIC_DASHBOARD, EASM_EMAIL_CONFIGURATIONS, EASM_ASSETS} from "./utils/constants";
import axios from "axios";

export const useDomainValidate = () => {
    return useMutation(
        async (data) => {
            try {
                const response = await axios.post(`/easm/domain/validate`, data);
                return response.data;
            } catch (error) {
                return error.response.data || error;
            }
        }
    );
}

export const useAddDomain = () => {
    return useMutation(
        async (data) => {
            try {
                const response = await axios.post(`/easm/domain`, data);
                return response.data;
            } catch (error) {
                return error.response.data || error;
            }
        }
    );
}

export const useEASMOverallDashboard = (companyId) => {
    return useQuery(EASM_OVERALL_DASHBOARD, async () => {
        let res = await axios.get(`/easm/overall_dashboard?companyId=${companyId}`)
        return res.data.data;
    })
}

export const useCheckEASMAssetExist = (companyId) => {
    return useQuery(EASM_ASSETS, async () => {
        let res = await axios.get(`/easm/check_asset_exist?companyId=${companyId}`)
        return res?.data?.assetFound;
    })
}

export const useDomainOverallDashboard = (assetId) => {
    return useQuery(DOMAIN_OVERALL_DASHBOARD, async () => {
        let res = await axios.get(`/easm/domain/overall_dashboard?assetId=${assetId}`)
        return res.data.data;
    })
}

export const useDomainSpecificDashboard = (assetId) => {
    return useQuery(DOMAIN_SPECIFIC_DASHBOARD, async () => {
        let res = await axios.get(`/easm/${assetId}/dashboard`)
        return res.data.data;
    })
}

export const useEASMInsightDetails = (companyId, insightId, type = null, assetId = null) => {
    return useQuery([EASM_INSIGHTS_DETAILS, companyId, insightId], async () => {
        let res = await axios.get(`/easm/insight_details?companyId=${companyId}&insightId=${insightId}&type=${type}&assetId=${assetId}`)
        return res.data;
    })
}

export const useEASMEmailConfiguration = (companyId, type) => {
    return useQuery([EASM_EMAIL_CONFIGURATIONS, companyId, type], async () => {
        let res = await axios.get(`/easm/email_configuration?companyId=${companyId}&type=${type}`)
        return res.data;
    })
}