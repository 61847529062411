import React from 'react';
import {
    AllAssets, AllAssetsFilter, AllBlockers,
    AllFindings, AllAssetsByTeam, AllPentestsByTeam,
    AllFindingsFilter, AllPentests, AllPentestsFilter, AssetDashboard, AssetDescAndUserStories,
    Dashboard, EditAssetScope, EnterpriseDashboard,
    FindingDashboard,
    MyCommunity,
    MyCustomers,
    MyTeam, PentestDashboard, PentestLandscape, PreRequisites, Chat, AllHighRiskAssets, GenPT, QualysFindingDashboard, ScanQualysFindingDashboard, CompanyDetails, VADashboard, GenPTTest, GenPTTestFindings, GenPTFindings,
    CTEMDashboard,
    CustomerSelection,
    AllEASM,
    EASMMonitoring,
    EASMOverallDashboard,
    DomainDashboard,
    SubdomainDashboard
} from "../pages/PageListAsync";
import { Route, Routes, Navigate } from "react-router-dom";
import Layout from "../../components/Admin/Layout";
import LayoutProvider from "../../Providers/LayoutProvider";
import withPermissions from "../../hoc/withPermissions";
import { SIEMBA_ADMIN } from "../../utils/roles";
import UserManagement from '../pages/Admin/UserManagement';
import AssetManagement from '../pages/Admin/AssetManagement';
import { SIEMBAGPT_ENABLED } from '../../utils/constants';
import VulnerabilityAssessment from '../pages/Admin/VulnerabilityAssessment';
import { useSelector } from 'react-redux';
import AllVAVulnerabilities from '../pages/Admin/VulnerabilityAssessment/AllVAVulnerabilities';

const AdminRoutes = () => {
    const { integrationsEnabled } = useSelector(state => state.auth);
    const is_genpt_enabled = SIEMBAGPT_ENABLED === 'true' && integrationsEnabled?.is_genpt_enabled;

    return (
        <LayoutProvider value={{
            dashboard: "ADMIN_DASHBOARD",
            mainUrl: "/admin"
        }}>
            <Layout>
                <Routes>
                    <Route path={"/"} element={<Navigate to={"/admin/dashboard"} />} />
                    <Route path={"/dashboard"} element={<Dashboard />} />
                    <Route path={"community"} element={<MyCommunity />} />
                    <Route path={"customers"} element={<MyCustomers />} />
                    <Route path={"user_management"} element={<UserManagement />} />
                    <Route path={"asset_management"} element={<AssetManagement />} />
                    <Route path={"all_va"} element={<VulnerabilityAssessment />} />
                    <Route path={"va/all_findings"} element={<AllVAVulnerabilities />} />

                    {/* CTEM dashboard */}
                    <Route path={"dashboard/:companyId/ctem_dashboard"} element={<CTEMDashboard />} />

                    {/* Team routes */}
                    <Route path={"team"} element={<MyTeam />} />
                    <Route path={"team/:teamId/all_assets"} element={<AllAssetsByTeam />} />
                    <Route path={"team/:teamId/all_pentests"} element={<AllPentestsByTeam />} />

                    {/*all findings*/}
                    <Route path={"pentests/all_findings"} element={<AllFindings />} />
                    <Route path={"pentests/all_findings/:id/dashboard"} element={<FindingDashboard />} />
                    <Route path={"/genpt/tests/:sessionId/findings/:id/dashboard"} element={<FindingDashboard from={'GENPT'}/>} />
                    <Route path={"/genpt/all_findings/:id/dashboard"} element={<FindingDashboard from={'GENPT'}/>} />
                    <Route path={"/pentests/all_findings/:filter"} element={<AllFindingsFilter />} />
                    <Route path={"/dashboard/:company_id/all_findings"} element={<AllFindings />} />
                    <Route path={"/dashboard/:company_id/all_findings/:filter"} element={<AllFindingsFilter />} />
                    <Route path={"/va/all_findings/:findingId/dashboard"} element={<QualysFindingDashboard />} />
                    <Route path={"/all_va/finding/:findingId/dashboard"} element={<ScanQualysFindingDashboard />} />

                    {/* EASM */}
                    <Route path={"/easm/insights"} element={<CustomerSelection />} />
                    <Route path={"/easm/insights/:companyId"} element={<EASMOverallDashboard />} />
                    <Route path={"/easm/attack_surface"} element={<AllEASM />} />
                    <Route path={'/easm/monitoring'} element={<EASMMonitoring />} />
                    <Route path={"/easm/monitoring/:id/:tabElement"} element={<DomainDashboard />} />
                    <Route path={"/easm/attack_surface/subdomain/:id"} element={<SubdomainDashboard />} />

                    {/*assets routes*/}
                    <Route path={"/all_assets"} element={<AllAssets />} />
                    <Route path={"/all_assets/:filter"} element={<AllAssetsFilter />} />
                    <Route path={"/all_assets/:company_id/:filter"} element={<AllAssetsFilter />} />
                    <Route path={"/all_assets/riskiest_assets"} element={<AllHighRiskAssets />} />
                    <Route path={"/all_assets/riskiest_assets/:pentest_id"} element={<AllHighRiskAssets />} />
                    <Route path={"/all_assets/:id/dashboard"} element={<AssetDashboard />} />
                    <Route path={"/all_va/:assetId/dashboard"} element={<VADashboard />} />
                    <Route path={"/all_assets/:id/edit_scope"} element={<EditAssetScope />} />
                    <Route path={"/all_assets/:id/asset_desc_and_user_stories"} element={<AssetDescAndUserStories />} />
                    <Route path={"/all_assets/:asset_id/all_findings"} element={<AllFindings />} />
                    <Route path={"/all_assets/:asset_id/all_findings/:filter"} element={<AllFindingsFilter />} />
                    <Route path={"/all_assets/:asset_id/pre_requisites"} element={<PreRequisites />} />
                    {/*pentests*/}
                    <Route path={"/all_pentests"} element={<AllPentests />} />
                    <Route path={"/pentests/:filter"} element={<AllPentestsFilter />} />
                    <Route path={"/all_pentests/:id/dashboard"} element={<PentestDashboard />} />
                    <Route path={"/all_pentests/:pentest_id/all_findings"} element={<AllFindings />} />
                    <Route path={"/all_pentests/:pentest_id/pentest_landscape"} element={<PentestLandscape />} />
                    <Route path={"/all_pentests/:pentest_id/all_findings/:filter"} element={<AllFindingsFilter />} />
                    <Route path={"/pentests/insights"} element={<CustomerSelection />} />
                    <Route path={"pentests/insights/:company_id"} element={<EnterpriseDashboard />} />
                    {/*blockers*/}
                    <Route path={"pentests/all_blockers"} element={<AllBlockers />} />
                    <Route path={"/dashboard/:company_id/all_blockers"} element={<AllBlockers />} />
                    <Route path={"/chat"} element={<Chat />} />
                    <Route path={"/chat/:conversation_id"} element={<Chat />} />
                    <Route path={"/genpt/tests"} element={<GenPT/>}/>
                    <Route path={"/genpt/tests/start"} element={<GenPTTest/>}/>
                    <Route path={"/genpt/tests/:session_id"} element={<GenPTTest/>}/>
                    <Route path={"/genpt/tests/:session_id/findings"} element={<GenPTTestFindings/>}/>
                    <Route path={"/genpt/all_findings"} element={<GenPTFindings/>}/>
                    <Route path={"/company-details"} element={<CompanyDetails />} />                    
                    <Route path={"/company-details/:company_id"} element={<CompanyDetails />} /> 
                </Routes>
            </Layout>
        </LayoutProvider>
    );
};

export default withPermissions(AdminRoutes, { roles: [SIEMBA_ADMIN] });
