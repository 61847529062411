import HomeIcon from "@mui/icons-material/Home";
import SearchIcon from '@mui/icons-material/Search';
import WysiwygIcon from '@mui/icons-material/Wysiwyg';
import ShieldIcon from '@mui/icons-material/Shield';
import DashboardIcon from '@mui/icons-material/Dashboard';
import {SIEMBA_CUSTOMER} from "../../../../utils/roles";
import AllBlockersIcon from "../../../SvgIcons/AllBlockersIcon";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import GPTIconWhite from "../../../SvgIcons/GPTIconWhite";
import VAIcon from "../../../SvgIcons/VALogo";
import EASMIcon from "../../../SvgIcons/EASMIcon";

const my_dashboard_menu = [
    {
        path: "/app/dashboard",
        icon: HomeIcon,
        label: "My Dashboard"
    },
    {
        path: "/app/ctem_dashboard",
        icon: DashboardIcon,
        label: "Mission Control",
        roles: [SIEMBA_CUSTOMER]
    },
    {
        path: "/app/all_assets",
        icon: WysiwygIcon,
        label: "All Assets"
    },
    {
        icon: EASMIcon,
        label: "EASM",
        child: [
            {
                path: "/app/easm/insights",
                label: "Insights"
            },
            {
                path: "/app/easm/attack_surface",
                label: "Attack Surface"
            },
            {
                path: "/app/easm/monitoring",
                label: "Monitoring"
            }
        ],
    },
    {
        icon: VAIcon,
        label: "Vul. Assessments",
        child: [
            {
                path: "/app/all_va",
                label: "All Scans"
            },
            {
                path: "/app/va/all_findings",
                label: "All Findings"
            }
        ],
    },
    {
        icon: GPTIconWhite,
        label: "GenPT",
        child: [
            {
                path: "/app/genpt/tests",
                label: "All Tests"
            },
            {
                path: "/app/genpt/all_findings",
                label: "All Findings"
            },
        ]
    },
    {
        icon: ShieldIcon,
        label: "Pentests",
        child: [
            {
                path: "/app/pentests/insights",
                label: "Insights",
                roles: [SIEMBA_CUSTOMER]
            },
            {
                path: "/app/all_pentests",
                label: "All Pentests"
            },
            {
                path: "/app/pentests/inflight",
                label: "Inflight"
            },
            // {
            //     path: "/app/pentests/retest",
            //     label: "Retests"
            // },
            {
                path: "/app/pentests/closed",
                label: "Closed"
            },
            {
                path: "/app/pentests/upcoming",
                label: "Upcoming"
            },
            // {
            //     path: "/app/pentests/pending",
            //     label: "Pending Approval"
            // },
            {
                path: "/app/pentests/draft",
                label: "Drafts"
            },
            {
                path: "/app/pentests/all_blockers",
                // icon: AllBlockersIcon,
                label: "All Blockers"
            },
            {
                path: "/app/pentests/all_findings",
                // icon: SearchIcon,
                label: "All Findings"
            },
        ]
    }, 
    {
        path: "/app/chat",
        icon: ChatBubbleOutlineIcon,
        label: "Chat"
    }
]
export default my_dashboard_menu;
